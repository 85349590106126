<div class="modal-dialog">
  <div class="modal-content ux-margin-0">
    <button type="button" class="close login_regclose" data-dismiss="modal" aria-label="Close" (click)="closeLogin()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <div class="registermodal_ctrl">
        <ul class="nav nav-tabs" id="myTab" role="tablist" [ngClass]="{ 'ux-tab-mobile': isMobile}">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
              aria-selected="true" (click)="toggleUserType('buyer_login')">
              <img src="../../../assets/images/buyer.png">{{!isMobile ? 'For' : ''}} Buyer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
              aria-selected="false" (click)="toggleUserType('builder_login')">
              <img src="../../../assets/images/builder.png">{{!isMobile ? 'For' : ''}} Builder</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
              aria-selected="false" (click)="toggleUserType('agent_login')">
              <img src="../../../assets/images/agent.png">{{!isMobile ? 'For' : ''}} Agent</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent" [ngClass]="{ 'ux-form-mobile': isMobile}">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div *ngIf="onlyEmailShow==false">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm,'buyer')">
                <input type="text" formControlName="email" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submitted && loginForm.controls.email.errors }" (blur)="buyerMailCheck(loginForm.get('email').value)" placeholder="Email"/>
                <div
                  *ngIf="(submitted && loginForm.controls.email.invalid) || (!loginForm.get('email').value && invalidForm) || (loginForm.get('email').invalid && loginForm.get('email').touched)"
                  class="has-error">
                  {{ (loginForm.get('email').value && loginForm.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                </div>
                <input type="password" formControlName="password" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors }" placeholder="Password"/>
                <div
                  *ngIf="(submitted && loginForm.controls.password.invalid) || (!loginForm.get('password').value && invalidForm) || (loginForm.get('password').invalid && loginForm.get('password').touched)"
                  class="has-error">
                  Password is required
                </div>
                <!-- <input type="text" class="form-control mb-2" placeholder="Enter Email..."  />
                      <input type="password" class="form-control mb-2" placeholder="Enter Password..."  /> -->
                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Login</button>
                <a href="javascript:void(0)" (click)="forgotYourPassword()">Forgot Password?</a>
              </form>

              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div>
              <div class="reg_socialiocon">
                <ul>
                  <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li>
                  <li><a href="#"><img src="../../../assets/images/insta.png" /></a></li>
                  <li><a (click)="signInWithPinterest()"><img src="../../../assets/images/printa.png" /></a></li>
                </ul>
              </div> -->
            </div>

            <div *ngIf="onlyEmailShow==true">
              <form [formGroup]="forgotPassword" (ngSubmit)="onSubmitForgotPassword(forgotPassword)">
                Email
                <input type="text" formControlName="email" class="form-control mb-2" (blur)="buyerForgotMailCheck(forgotPassword.get('email').value)"
                  [ngClass]="{ 'is-invalid': submittedEmail && forgotPassword.controls.email.errors }" placeholder="Email">
                <div
                  *ngIf="(submittedEmail && forgotPassword.controls.email.invalid) || (!forgotPassword.get('email').value && invalidForm2) || (forgotPassword.get('email').invalid && forgotPassword.get('email').touched)"
                  class="has-error">
                  {{ (forgotPassword.get('email').value && forgotPassword.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}

                </div>
                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                <a href="javascript:void(0)" (click)="ForLogin()">Back To Login</a>

              </form>

            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div *ngIf="builderEmailShow==false">
              <form [formGroup]="loginFormforBuilder" (ngSubmit)="onSubmitBuilderForm(loginFormforBuilder,'builder')">
                <input type="text" formControlName="email" class="form-control mb-2" (blur)="builderMailCheck(loginFormforBuilder.get('email').value)"
                  [ngClass]="{ 'is-invalid': submittedBuilder && loginFormforBuilder.controls.email.errors }" placeholder="Email"/>
                <div
                  *ngIf="(submittedBuilder && loginFormforBuilder.controls.email.invalid) || (!loginFormforBuilder.get('email').value && invalidFormForBuilder) || (loginFormforBuilder.get('email').invalid && loginFormforBuilder.get('email').touched)"
                  class="has-error">
                  {{ (loginFormforBuilder.get('email').value && loginFormforBuilder.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                </div>
                <input type="password" formControlName="password" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submittedBuilder && loginFormforBuilder.controls.password.errors }" placeholder="Password"/>
                <div
                  *ngIf="(submittedBuilder && loginFormforBuilder.controls.password.invalid) || (!loginFormforBuilder.get('password').value && invalidFormForBuilder) || (loginFormforBuilder.get('password').invalid && loginFormforBuilder.get('password').touched)"
                  class="has-error">
                  Password is required
                </div>

                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Login</button>
                <a href="javascript:void(0)" (click)="builderEmail()">Forgot Password?</a>
              </form>

              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div>
              <div class="reg_socialiocon">
                <ul>
                  <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li>
                  <li><a href="#"><img src="../../../assets/images/insta.png" /></a></li>
                  <li><a (click)="signInWithPinterest()"><img src="../../../assets/images/printa.png" /></a></li>
                </ul>
              </div> -->
            </div>

            <div *ngIf="builderEmailShow==true">
              <form [formGroup]="forgotPasswordBuilder"
                (ngSubmit)="onSubmitForgotPasswordBuilder(forgotPasswordBuilder)">
                Email
                <input type="text" formControlName="email" class="form-control mb-2" (blur)="builderForgotMailCheck(forgotPasswordBuilder.get('email').value)"
                  [ngClass]="{ 'is-invalid': submittedEmail1 && forgotPasswordBuilder.controls.email.errors }" placeholder="Email">
                <div
                  *ngIf="(submittedEmail1 && forgotPasswordBuilder.controls.email.invalid) || (!forgotPasswordBuilder.get('email').value && invalidForm3) || (forgotPasswordBuilder.get('email').invalid && forgotPasswordBuilder.get('email').touched)"
                  class="has-error">
                  {{ (forgotPasswordBuilder.get('email').value && forgotPasswordBuilder.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}

                </div>
                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                <a href="javascript:void(0)" (click)="ForLoginBuilder()">Back To Login</a>

              </form>

            </div>
          </div>
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <div *ngIf="buyerEmailShow==false">
              <form [formGroup]="loginFormforAgent" (ngSubmit)="onSubmitAgentForm(loginFormforAgent,'agent')">
                <input type="text" formControlName="email" class="form-control mb-2" (blur)="agentMailCheck(loginFormforAgent.get('email').value)"
                  [ngClass]="{ 'is-invalid': submittedAgent && loginFormforAgent.controls.email.errors }" placeholder="Email"/>
                <div
                  *ngIf="(submittedAgent && loginFormforAgent.controls.email.invalid) || (!loginFormforAgent.get('email').value && invalidFormAgent) || (loginFormforAgent.get('email').invalid && loginFormforAgent.get('email').touched)"
                  class="has-error">
                  {{ (loginFormforAgent.get('email').value && loginFormforAgent.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                </div>
                <input type="password" formControlName="password" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submittedAgent && loginFormforAgent.controls.password.errors }" placeholder="Password"/>
                <div
                  *ngIf="(submittedAgent && loginFormforAgent.controls.password.invalid) || (!loginFormforAgent.get('password').value && invalidFormAgent) || (loginFormforAgent.get('password').invalid && loginFormforAgent.get('password').touched)"
                  class="has-error">
                  Password is required
                </div>

                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Login</button>
                <a href="javascript:void(0)" (click)="forgotPasswordBuyer()">Forgot Password?</a>
              </form>

              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div> -->
              <!-- <div class="reg_socialiocon">
                <ul>
                  <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li> -->
                  <!-- <li><a href="#"><img src="../../../assets/images/insta.png" /></a></li>
                  <li><a (click)="signInWithPinterest()"><img src="../../../assets/images/printa.png" /></a></li> -->
                <!-- </ul>
              </div> -->
            </div>

            <div *ngIf="buyerEmailShow==true">
              <form [formGroup]="forgotPasswordAgent" (ngSubmit)="onSubmitForgotPasswordAgent(forgotPasswordAgent)">
                Email
                <input type="text" formControlName="email" class="form-control mb-2" (blur)="agentForgotMailCheck(forgotPasswordAgent.get('email').value)"
                  [ngClass]="{ 'is-invalid': submittedEmail2 && forgotPasswordAgent.controls.email.errors }" placeholder="Email">
                <div
                  *ngIf="(submittedEmail2 && forgotPasswordAgent.controls.email.invalid) || (!forgotPasswordAgent.get('email').value && invalidForm4) || (forgotPasswordAgent.get('email').invalid && forgotPasswordAgent.get('email').touched)"
                  class="has-error">
                  {{ (forgotPasswordAgent.get('email').value && forgotPasswordAgent.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}

                </div>
                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                <a href="javascript:void(0)" (click)="ForLoginBuyer()">Back To Login</a>

              </form>

            </div>
          </div>

        </div>

      </div>


    </div>

  </div>
</div>