import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CookieStorageService } from '../services/cookie-storage.service';

import { LocalVars } from '../_constants/others.constants';
import { CommonService } from '../services/common.service';
import { AdvanceSearchComponent } from '../property/advance-search/advance-search.component';
import { DetailsComponent } from '../property/details/details.component';
import { ShopTheLookDisplayComponent } from '../property/shop-the-look-display/shop-the-look-display.component';
import { ListComponent } from '../property/list/list.component';
import { AdminStlShowComponent } from '../stl-admin/admin-stl-show/admin-stl-show.component';
import { AdminAddModelComponent } from '../property/admin-add-model/admin-add-model.component';
import { CommonStorageService } from '../services/common-storage.service';


@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
	) {

	}

	canActivate(
		
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
			
		// console.log(this.commonStorage.checkItem(LocalVars.TOKEN_VAR), this.commonStorage.checkItem(LocalVars.USER), 'ttttttttt')
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.commonStorage.getItem(LocalVars.USER_VAR));
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}


@Injectable()
export class LoginGuard implements CanActivate {
	constructor(private router: Router, private cookieStorage: CookieStorageService,private commonStorage: CommonStorageService) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {			
			if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {
				this.router.navigate([''], { replaceUrl: true });
			}
			
			return true;
	}
}

@Injectable()
export class ResetPaswordPageGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
		private commonService: CommonService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR)) {
			this.commonService.doLogOut();
			this.commonStorage.clear();
		}

		return true;
	}
}

@Injectable()
export class AdvanceSearchDeactivateGuard implements CanDeactivate<AdvanceSearchComponent> {
	canDeactivate(component: AdvanceSearchComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}

@Injectable()
export class BuilderGuard implements CanActivate {
	constructor(
		private router: Router, 
		private commonStorage: CommonStorageService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.commonStorage.getItem(LocalVars.USER_VAR));
			//let getUser = this.cookieStorage.getDetailsItem(LocalVars.USER);
			let user = this.commonStorage.getDetailsItem(LocalVars.USER);
			if(user && user.role_slug != 'builder'){
				this.router.navigate(['']);
			}
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}

@Injectable()
export class BuyerGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.commonStorage.getItem(LocalVars.USER_VAR));
			let user = this.commonStorage.getDetailsItem(LocalVars.USER);
			console.log(user.role_slug, 'user.role_slug')
			if(user && user.role_slug != 'buyer'){
				this.router.navigate(['']);
			}
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}


@Injectable()
export class AgentGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
	) {

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.cookieStorage.getItem(LocalVars.USER_VAR));
			let user = this.commonStorage.getDetailsItem(LocalVars.USER);
			if(user && user.role_slug != 'buyer'){
				this.router.navigate(['']);
			}
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}

@Injectable()
export class BuilderAgentGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.commonStorage.getItem(LocalVars.USER_VAR));
			let user = this.commonStorage.getDetailsItem(LocalVars.USER);
			if(user && user.role_slug != 'builder' && user.role_slug != 'agent'){
				this.router.navigate(['']);
			}
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}

@Injectable()
export class BuyerAgentGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonStorage: CommonStorageService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (this.commonStorage.checkItem(LocalVars.TOKEN_VAR) && this.commonStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.commonStorage.getItem(LocalVars.USER_VAR));
			let user = this.commonStorage.getDetailsItem(LocalVars.USER);
			console.log(user.role_slug);
			
			if(user && user.role_slug != 'buyer' && user.role_slug != 'agent'){
				this.router.navigate(['']);
			}
		} else {
			this.router.navigate(['/home']);
		}
		
		return true;
	}
}

@Injectable()
export class PropertyDetailsDeactivateGuard implements CanDeactivate<DetailsComponent> {
	canDeactivate(component: DetailsComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {
		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}

@Injectable()
export class STLDisplayDeactivateGuard implements CanDeactivate<ShopTheLookDisplayComponent> {
	canDeactivate(component: ShopTheLookDisplayComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {
		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}

@Injectable()
export class PropertyListDeactivateGuard implements CanDeactivate<ListComponent> {
	canDeactivate(component: ListComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {
		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}


@Injectable()
export class AdminSTLDeactivateGuard implements CanDeactivate<AdminStlShowComponent> {
	canDeactivate(component: AdminStlShowComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {
		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}

@Injectable()
export class AdminModelDeactivateGuard implements CanDeactivate<AdminAddModelComponent> {
	canDeactivate(component: AdminAddModelComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	  ): Observable<boolean>|Promise<boolean>|boolean {
		
	  return component.canDeactivate(component, currentRoute, currentState, nextState);
	}
}




