export const environment = {

    // production: true,

    //API: 'http://10.25.26.109/new-built-backend/public/api/v1/',
    // API: 'http://10.25.26.101/newbuilt-backend/new-built-backend/public/api/v1/',
    // BASE_URL: 'http://localhost:8080/',
    // API_BASE_URL: 'http://10.25.26.119/newbuilt/public/api/v1/',
    // ASSET_URL: 'http://localhost/',
    // SOCKET_URL: 'https://newbuilt.glohtesting.com/',
    // SITE_URL: 'https://newbuilt.glohtesting.com/',
    // PUSHER_KEY: '934096a51d5f68961e41',
    // PUSHER_CLUSTER: 'us2',
    // ASSET_360_URL: 'https://newbuilt.glohtesting.com/property360files/',

    // firebaseConfig : {
    //     apiKey: "AIzaSyApcUn_nzDAGRdFmaTGtQGCD23xOxyYD78",
    //     authDomain: "new-built.firebaseapp.com",
    //     databaseURL: "https://new-built.firebaseio.com",
    //     projectId: "new-built",
    //     storageBucket: "new-built.appspot.com",
    //     messagingSenderId: "312218382806",
    //     appId: "1:312218382806:web:383094605490325b47f477",
    //     measurementId: "G-FM1TFB1K6J"
    // }

    production: false,   
    // API: 'http://10.25.26.109/new-built-backend/public/api/v1/',
    // API: 'http://10.25.26.33/new-built-backend/public/api/v1/',
    //API: 'http://10.25.26.101/newbuilt-backend/new-built-backend/public/api/v1/',//'https://newbuilt-backend.glohtesting.com/api/v1/',
    //API: 'http://10.25.26.101/newbuilt-backend/new-built-backend/public/api/v1/',
    //API: 'https://newbuilt-backend.glohtesting.com/api/v1/',
    API: 'https://newbuild-dev.dreamztesting.com/api/v1/',
   
    // API:'https://newbuilt-live-backend.glohtesting.com/api/v1/',
    // API: 'https://newbuilt-live-backend.glohtesting.com/api/v1/',
    BASE_URL: 'http://localhost:8080/',
    API_BASE_URL: 'https://newbuilt-backend.glohtesting.com/',
    ADMIN_URL: 'http://localhost/newbuilt-backend/new-built-backend/public/admin/home',
    ASSET_URL: 'https://newbuilt.glohtesting.com/',
    SOCKET_URL: 'https://newbuilt-backend.glohtesting.com/',
    SITE_URL: 'https://newbuilt.glohtesting.com/',
    PUSHER_KEY: '934096a51d5f68961e41',
    PUSHER_CLUSTER: 'us2',
    ASSET_360_URL: 'https://newbuilt.glohtesting.com/property360files/',

    // firebaseConfig : {
    //     apiKey: "AIzaSyApcUn_nzDAGRdFmaTGtQGCD23xOxyYD78",
    //     authDomain: "new-built.firebaseapp.com",
    //     databaseURL: "https://new-built.firebaseio.com",
    //     projectId: "new-built",
    //     storageBucket: "new-built.appspot.com",
    //     messagingSenderId: "312218382806",
    //     appId: "1:312218382806:web:383094605490325b47f477",
    //     measurementId: "G-FM1TFB1K6J"
    // }

    firebaseConfig : {
        apiKey: "AIzaSyBsMR_Yr5ylL6KJQmqnPArBgwu9Fheh4Tg",
        authDomain: "new-built-9846a.firebaseapp.com",
        databaseURL: "https://new-built-9846a.firebaseio.com",
        projectId: "new-built-9846a",
        storageBucket: "new-built-9846a.appspot.com",
        messagingSenderId: "408907478974",
        appId: "1:408907478974:web:1e82699d19a0fa593a6bef",
        measurementId: "G-9037FMMWRQ"
    },
        
    stripeConfig : {
        production: false,
        stripe: {
          publicKey: 'pk_test_yq4HDWUf2rhjNhHYX86aWgSG00PSSlaO2z',
        }
      }

    
};
