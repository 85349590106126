import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebLayoutComponent } from './layout/web-layout/web-layout.component';
import { LoginGuard, AuthGuard } from './_guards/auth.guard';
import { NotFoundComponent } from './_components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'home',
    component: WebLayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate:[LoginGuard]
  },
  {
    path: '',
    component: WebLayoutComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'property',
    component: WebLayoutComponent,
    loadChildren: () => import('./property/property.module').then(m => m.PropertyModule),
    canActivate:[]
  },
  {
    path: 'profile',
    component: WebLayoutComponent,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate:[AuthGuard]
  },
  {
    path: '',
    component: WebLayoutComponent,
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate:[]
  },
  {
      path: '',
      component: WebLayoutComponent,
      loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
      canActivate:[]
  },
  {
    path: 'contact',
    component: WebLayoutComponent,
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate:[]
  },
  {
    path: 'buyer',
    component: WebLayoutComponent,
    loadChildren: () => import('./buyer/buyer.module').then(m => m.BuyerModule),
    canActivate:[]
  },
  {
    path: 'agent',
    component: WebLayoutComponent,
    loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule),
    canActivate:[]
  },
  {
    path: 'manage',
    component: WebLayoutComponent,
    loadChildren: () => import('./stl-admin/stl-admin.module').then(m => m.StlAdminModule),
    canActivate:[]
  },

  // Others
	{ path: 'page-not-found', component: NotFoundComponent },
	{ path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
