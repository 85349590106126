import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, TemplateRef,HostListener } from '@angular/core';
import { CookieStorageService } from 'src/app/services/cookie-storage.service'
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from './services/notification.service';
import { BsModalService, BsModalRef, ModalOptions, ModalDirective } from 'ngx-bootstrap/modal';
import { Regex, LocalVars, DefaultAddress, DefaultAddressArr, DefaultLatLng } from './_constants/others.constants';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from './services/loader.service';
import { UserService } from './services/user.service';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { SocialAuthService } from 'angularx-social-login';
import { CommonService } from './services/common.service';
import Swal from 'sweetalert2'
import { CommonStorageService } from './services/common-storage.service';
//import { setTheme } from 'ngx-bootstrap/utils';


// declare ga as a function to set and sent the events
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'new-built';
  show: any;
  public modalCardRef: BsModalRef;
  public modalPaymentRef: BsModalRef;
  public modalSubscriptionRef: BsModalRef;
  public tierDetails: any;
  public subscriptionTierDetails: any;
  public params: any = {};
  public paymentModalOpen: boolean = false;
  public subscriptionModalOpen: boolean = false;
  public paymentNewCardModalOpen: boolean = false;
  public isSubmit: boolean = false;
  public isSubscriptionSubmit: boolean = false;
  public count: number = 0;
  public subscriptions: Subscription[] = [];
  public existingAgent: boolean = false;
  public cardList: Array<any> = [];
  public user: any;
  public isPaymentSubmit: boolean = false;
  // public subscriptions: Subscription[] = [];
  locations:any;


  @ViewChild('paymentTemplate', { static: true }) paymentTemplate: TemplateRef<any>;
  @ViewChild('savedCardTemplate', { static: true }) savedCardTemplate: TemplateRef<any>;
  //@ViewChild('subscriptionTemplate', { static: true }) subscriptionTemplate: TemplateRef<any>;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    this.locations = DefaultAddress;
    this.commonService.updateCurrentAddress(DefaultAddress);

    localStorage.setItem('currentLocationStr', DefaultAddress);
    localStorage.setItem('currentLocationObj', JSON.stringify(DefaultAddressArr));
    localStorage.setItem('currentLatLng', JSON.stringify(DefaultLatLng));
  }

  constructor(
    private cookieStorage: CookieStorageService,
    private notificationSErvice: NotificationService,
    private modalService: BsModalService,
    private userService: UserService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private commonService: CommonService,
    private authService: SocialAuthService,
    private cookieService: CookieService,
    private commonStorage: CommonStorageService,
    private router: Router
  ) {
    //setTheme('bs4');
    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-FM1TFB1K6J',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    })



  }

  ngOnInit() {

    this.router.events.subscribe((event: NavigationEnd) => {
      window.scroll(0, 0);
    });
    this.isSubmit = false;


    setInterval(() => {


      let user = this.commonStorage.getDetailsItem(LocalVars.USER);
      if(user) {
        this.userService.me()
        .then(r => {
          if (r['data'] && r['data']['user']) {
            let user = r['data']['user'];
            //console.log('user', user)
            if (user && (user.role_slug == 'agent' || user.role_slug == 'buyer')) {
                if(user.status == 0) {
                  // console.log('hgfhf')
                  this.logoutConfirm();
                }
            }
            if (user && user.role_slug == 'builder') {
              if(user.status == 0 || user.admin_approval == 2) {
                this.logoutConfirm();
              }
            }
          } 
          // else {
          //   this.toaster.error('User details not found!', 'User fetch')
          // }
        }).catch(err => {
          // const message = err && err.error && err.error.message || "Something went wrong!";
          // this.toaster.error(message, 'Login Error')
      })

      }

     
      this.user = user;
      if (user && user.role_slug == 'agent') {
        // console.log(this.paymentModalOpen, this.isSubmit, user.agent_subscription)
        if (user.agent_subscription && user.agent_subscription.status == 2) {
          this.tierDetails = user.agent_subscription.subscription;
          this.params['subscription_id'] = this.tierDetails.id;
          if (!this.paymentModalOpen && !this.isSubmit) {
            this.openCardModalTemplate();
          }
          // console.log(this.paymentNewCardModalOpen, this.isPaymentSubmit, this.isSubmit, 'kiocxc2')
          if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit && this.isSubmit) {
            this.openPaymentTemplate();
          }
        }
      }

      if (user && user.role_slug == 'agent') {
        console.log("hell",user.agent_subscription);
        
        if (!user.agent_subscription) {
          if (!this.subscriptionModalOpen && !this.isSubscriptionSubmit) {
            this.existingAgent = true;
            //this.openSubscriptionTemplate();
          }
          // console.log(this.paymentNewCardModalOpen, this.isPaymentSubmit, 'kiocxc2')
          if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit && this.isSubmit) {
            this.openPaymentTemplate();
          }
        }
      }

      // if (user && user.role_slug == 'builder') {
      //   // console.log(this.paymentNewCardModalOpen, this.isPaymentSubmit, this.isSubmit)
      //   if (user.builder_subscription && user.builder_subscription.status == 2) {
      //     this.tierDetails = user.builder_subscription.subscription_plan;
      //     this.params['subscription_id'] = this.tierDetails.id;
      //     if (!this.paymentModalOpen && !this.isSubmit) {
      //       this.openCardModalTemplate();
      //     }
      //     if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit && this.isSubmit) {
      //       this.openPaymentTemplate();
      //     }
      //   }
      // }

    }, 1500)

    //   }

    // });


    this.notificationSErvice.requestPermission()
    this.notificationSErvice.receiveMessage()
    this.show = this.notificationSErvice.currentMessage;
    // console.log('show', this.show)

  }

  getUserDetails() {
    this.userService.me()
      .then(r => {
        if (r['data'] && r['data']['user']) {
          this.completeLogin(r['data']['user']);
        } else {
          this.toaster.error('User details not found!', 'User fetch')
        }
      }).catch(err => {
        // console.log(err)
        const message = err && err.error && err.error.message || "Something went wrong!";
        this.toaster.error(message, 'Login Error')
      })
  }

  private completeLogin(user: any) {
    //this.user = user;
    this.commonStorage.setItem(LocalVars.USER_VAR, btoa(user.uid));
    this.commonStorage.setDetailsItem(LocalVars.USER, user);

    this.router.navigate([''], { replaceUrl: true });
  }

  private openCardModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: false, backdrop: "static", keyboard: false }) {
    this.modalCardRef = this.modalService.show(template, options);
    this.paymentModalOpen = true;


    this.subscriptions.push(
      this.modalCardRef.onHidden.subscribe((reason: string | any) => {
        this.paymentModalOpen = false;
        let user = this.commonStorage.getDetailsItem(LocalVars.USER);
        if (user && user.role_slug == 'agent') {
          if (user.agent_subscription && user.agent_subscription.status == 2) {
            this.tierDetails = user.agent_subscription.subscription;
            this.params['subscription_id'] = this.tierDetails.id;
            if (!this.paymentModalOpen && !this.isSubmit) {
              this.openCardModalTemplate();
            }
          }

          if (!user.agent_subscription) {
            this.tierDetails = this.subscriptionTierDetails;
            this.params['subscription_id'] = this.tierDetails.id;
            if (!this.paymentModalOpen && !this.isSubmit) {
              //this.openSubscriptionTemplate();
            }
          }
        }

        // if (user && user.role_slug == 'builder') {
        //   // console.log(this.tierDetails, user.agent_subscription.status)
        //   if (user.builder_subscription && user.builder_subscription.status == 2) {
        //     this.tierDetails = user.builder_subscription.subscription_plan;
        //     this.params['subscription_id'] = this.tierDetails.id;
        //     if (!this.paymentModalOpen && !this.isSubmit) {
        //       this.openCardModalTemplate();
        //     }
        //   }
        // }
      })
    );
  }

  private openPaymentModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: false, backdrop: "static", keyboard: false }) {
    // console.log('open-payment-modal')
    this.modalPaymentRef = this.modalService.show(template, options);
    this.paymentNewCardModalOpen = true;


    this.subscriptions.push(
      this.modalPaymentRef.onHidden.subscribe((reason: string | any) => {
        this.paymentNewCardModalOpen = false;
        // console.log(this.paymentNewCardModalOpen, this.isPaymentSubmit, 'kiocxc')
        let user = this.commonStorage.getDetailsItem(LocalVars.USER);
        if (user && user.role_slug == 'agent') {
          if (user.agent_subscription && user.agent_subscription.status == 2) {
            this.tierDetails = user.agent_subscription.subscription;
            this.params['subscription_id'] = this.tierDetails.id;
            if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit) {
              this.openPaymentTemplate();
            }
          }

          if (!user.agent_subscription) {
            this.tierDetails = this.subscriptionTierDetails;
            this.params['subscription_id'] = this.tierDetails.id;
            if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit) {
              this.openPaymentTemplate();
            }
          }
        }

        // if (user && user.role_slug == 'builder') {
        //   // console.log(this.tierDetails, user.agent_subscription.status)
        //   if (user.builder_subscription && user.builder_subscription.status == 2) {
        //     this.tierDetails = user.builder_subscription.subscription_plan;
        //     this.params['subscription_id'] = this.tierDetails.id;
        //     if (!this.paymentNewCardModalOpen && !this.isPaymentSubmit) {
        //       this.openPaymentTemplate();
        //     }
        //   }
        // }
      })
    );
  }

  private openSubscriptionModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: false, backdrop: "static", keyboard: false }) {
    this.modalSubscriptionRef = this.modalService.show(template, options);
    this.subscriptionModalOpen = true;
    this.subscriptions.push(
      this.modalSubscriptionRef.onHidden.subscribe((reason: string | any) => {
        this.subscriptionModalOpen = false;
        let user = this.commonStorage.getDetailsItem(LocalVars.USER);
        if (user && user.role_slug == 'agent') {
          if (!user.agent_subscription) {
            if (!this.subscriptionModalOpen && !this.isSubscriptionSubmit) {
              this.tierDetails = this.subscriptionTierDetails;
              //this.openSubscriptionTemplate();
            }
          }
        }
      })
    );
  }

  openCardModalTemplate() {
    this.openCardModal(this.savedCardTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  openPaymentTemplate() {
    this.openPaymentModal(this.paymentTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  /* openSubscriptionTemplate() {
    this.openSubscriptionModal(this.subscriptionTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  } */

  paymentModalClose() {
    this.modalPaymentRef.hide();
    // this.openCardModalTemplate();
    this.isPaymentSubmit = true;
  }

  existingAgentPaymentModalClose() {
    this.modalPaymentRef.hide();
    this.isPaymentSubmit = true;
    // this.isSubmit = true;
  }

  savedCardModalClose() {
    this.modalCardRef.hide();
    this.paymentModalOpen = false;
    this.isSubmit = true;
  }

  newCardCheck(val) {
    // console.log('newCardCheck', val)
    if (val) {
      this.savedCardModalClose();
      this.openPaymentTemplate();
    }
  }

  savePaymentModal(value) {
    // console.log('savedCardCheck', value);
    if (this.existingAgent) {
      console.log(this.paymentNewCardModalOpen, this.isPaymentSubmit)
      this.existingAgentPaymentModalClose();
      this.getUserDetails();
    }
    else {
      if (value) {
        this.params['card_id'] = value['id'];
        console.log('params', this.params)
        this.loader.show();
        if (this.user.role_slug == 'agent') {
          this.userService.repeatPayment(this.params)
            .then(r => {
              this.loader.hide();
              this.paymentModalClose();
              this.getUserDetails()
              this.paymentModalOpen = false;
              if (r['data']) {
                this.toaster.success(r.message, 'Success');
              } else {
                this.toaster.error(r.message, 'Oops')
              }
              // this.loading = false;
            }).catch(err => {
              this.loader.hide();
              // this.loading = false;
              if (err.status == 400) {
                this.toaster.error(err.error.message, 'Oops')

              } else if (err.message) {
                this.toaster.error(err.message, 'Oops')
              } else {
                this.toaster.error('Something went wrong!', 'Oops')
              }

            })

        }
        // if (this.user.role_slug == 'builder') {
        //   this.userService.repeatBuilderPayment(this.params)
        //     .then(r => {
        //       this.loader.hide();
        //       this.paymentModalClose();
        //       this.getUserDetails()
        //       this.paymentModalOpen = false;
        //       if (r['data']) {
        //         this.toaster.success(r.message, 'Success');
        //       } else {
        //         this.toaster.error(r.message, 'Oops')
        //       }
        //       // this.loading = false;
        //     }).catch(err => {
        //       this.loader.hide();
        //       // this.loading = false;
        //       if (err.status == 400) {
        //         this.toaster.error(err.error.message, 'Oops')

        //       } else if (err.message) {
        //         this.toaster.error(err.message, 'Oops')
        //       } else {
        //         this.toaster.error('Something went wrong!', 'Oops')
        //       }

        //     })

        // }
      }
    }
  }

  savedCardPayment(value) {
    console.log('savedCardPayment', value);
    if (value) {
      this.params['card_id'] = value;
      this.loader.show();
      if (this.user.role_slug == 'agent') {
        this.userService.repeatPayment(this.params)
          .then(r => {
            this.savedCardModalClose();
            this.loader.hide();
            this.getUserDetails()
            this.paymentModalOpen = false;
            if (r['data']) {
              this.toaster.success(r.message, 'Success');
            } else {
              this.toaster.error(r.message, 'Oops')
            }
          }).catch(err => {
            this.loader.hide();
            if (err.status == 400) {
              this.toaster.error(err.error.message, 'Oops')

            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })
      }
      // if (this.user.role_slug == 'builder') {
      //   this.userService.repeatBuilderPayment(this.params)
      //     .then(r => {
      //       this.loader.hide();
      //       this.savedCardModalClose();
      //       this.getUserDetails()
      //       this.paymentModalOpen = false;
      //       if (r['data']) {
      //         this.toaster.success(r.message, 'Success');
      //       } else {
      //         this.toaster.error(r.message, 'Oops')
      //       }
      //       // this.loading = false;
      //     }).catch(err => {
      //       this.loader.hide();
      //       // this.loading = false;
      //       if (err.status == 400) {
      //         this.toaster.error(err.error.message, 'Oops')

      //       } else if (err.message) {
      //         this.toaster.error(err.message, 'Oops')
      //       } else {
      //         this.toaster.error('Something went wrong!', 'Oops')
      //       }

      //     })
      // }
    }
  }



  onActivate(event) {
    window.scroll(0, 0);

  }

  subscriptionValue(value) {
    if (value[0]) {
      this.modalSubscriptionRef.hide();
      this.isSubscriptionSubmit = true;
      this.subscriptionTierDetails = value[0];
      this.tierDetails = this.subscriptionTierDetails;
      this.getAllCard();
    }
  }

  public getAllCard() {
    // this.loader.show()
    this.userService.getCardList()
      .then(res => {
        // this.loader.hide();
        this.cardList = res.data[0].data;
        console.log('card-list', this.cardList)
        if (this.cardList.length == 0) {
          this.openPaymentTemplate()
        }
        if (this.cardList.length > 0) {
          this.openCardModalTemplate();
        }
      }).catch(err => {
        // this.loader.hide();
        // this.loading = false;
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }

  logout(type) {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      // html: `<h3 style="font-weight:500;">Are you sure you want to logout?</h3>`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Sure',
      cancelButtonText: 'No',
      confirmButtonColor: '#f37135',
      width: '25rem'
    }).then((result) => {
      if (result.value) {
        this.logoutConfirm();
        if(type == 'pay') {
          this.modalCardRef.hide();
        }
        if(type == 'subs') {
          this.modalSubscriptionRef.hide();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }

  logoutConfirm() {
    this.loader.show();
    // this.socialLogout()

    setTimeout(() => {
      this.loader.hide();
      this.commonService.doLogOut();
      this.clearAll();
    }, 200);
  }

  public socialLogout() {
    this.authService.authState.subscribe((user) => {
      if (user != null) {
        this.authService.signOut();
      }
    })
  }

  private clearAll() {
    this.cookieService.deleteAll('/');
    this.commonStorage.clear();
    this.user = '';
    this.router.navigate(['/home']);
  }



  public executeImportantAction(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => console.log(token));
  }

  // onHideModal(type: string, $event: ModalDirective) {
  //   // console.log('opop', type, event)
  //   alert('check')
  // }
}
