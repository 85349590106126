<!-- <div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
<div class="modal-dialog ">
  <div class="modal-content ux-margin-0">
    <button type="button" class="close login_regclose" data-dismiss="modal" aria-label="Close" (click)="closeSignup()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div [ngClass]="userType == 'agent' ? 'modal-body-agent modal-body' : 'modal-body'">
      <div class="registermodal_ctrl">
        <ul class="nav nav-tabs" id="myTab" role="tablist" [ngClass]="{ 'ux-tab-mobile': isMobile}">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
              aria-selected="true" (click)="toggleUserType('buyer')">
              <img src="../../../assets/images/buyer.png">{{!isMobile ? 'For' : ''}} Buyer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
              aria-selected="false" (click)="toggleUserType('builder')">
              <img src="../../../assets/images/builder.png">{{!isMobile ? 'For' : ''}} Builder</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
              aria-selected="false" (click)="toggleUserType('agent')">
              <img src="../../../assets/images/agent.png">{{!isMobile ? 'For' : ''}} Agent</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent" [ngClass]="{ 'ux-form-mobile': isMobile}">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div>
              <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <input type="text" formControlName="first_name" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submitted && signupForm.controls.first_name.errors }"
                  placeholder="Enter First Name" />
                <div
                  *ngIf="(submitted && signupForm.controls.first_name.invalid) || (!signupForm.get('first_name').value && invalidForm) || (signupForm.get('first_name').invalid && signupForm.get('first_name').touched)"
                  class="has-error">First Name is required
                </div>
                <input type="text" formControlName="last_name" class="form-control mb-2"
                  [ngClass]="{ 'is-invalid': submitted && signupForm.controls.last_name.errors }"
                  placeholder="Enter Last Name" />
                <div
                  *ngIf="(submitted && signupForm.controls.last_name.invalid) || (!signupForm.get('last_name').value && invalidForm) || (signupForm.get('last_name').invalid && signupForm.get('last_name').touched)"
                  class="has-error"> Last Name is required
                </div>
                <input type="text" formControlName="email" class="form-control mb-2"
                  (blur)="buyerMailCheck(signupForm.get('email').value)"
                  [ngClass]="{ 'is-invalid': submitted && signupForm.controls.email.errors }" autocomplete="off"
                  placeholder="Enter Email" />
                <div
                  *ngIf="(submitted && signupForm.controls.email.invalid) || (!signupForm.get('email').value && invalidForm) || (signupForm.get('email').invalid && signupForm.get('email').touched)"
                  class="has-error">
                  {{ (signupForm.get('email').value && signupForm.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                </div>
                <input type="password" class="form-control mb-2" placeholder="Enter Password" formControlName="password"
                  [ngClass]="{'is-invalid': (!signupForm.get('password').value && invalidForm) || (signupForm.get('password').invalid && signupForm.get('password').touched)}"
                  (keyup)="passwordValidator($event)">
                <div
                  *ngIf="(invalidPassword) || (!signupForm.get('password').value && invalidForm) || (signupForm.get('password').invalid && signupForm.get('password').touched)"
                  class="has-error">{{ (invalidPassword) ? 'Password is invalid' : 'Password is required'}}
                </div>
                <input type="password" class="form-control mb-2" placeholder="Enter Confirm Password"
                  formControlName="c_password"
                  [ngClass]="{'is-invalid': (!signupForm.get('c_password').value && invalidForm) || (signupForm.get('c_password').invalid && signupForm.get('c_password').touched) ||(mismatchPassword)}"
                  (keyup)="compareConfirmPasswordWithNew($event)">
                <div
                  *ngIf="(mismatchPassword) || (!signupForm.get('c_password').value && invalidForm) || (signupForm.get('c_password').invalid && signupForm.get('c_password').touched)"
                  class="has-error">{{ (mismatchPassword) ? 'Mismatched Password' : 'Confirm Password is required'}}
                </div>
                <!-- <input type="text" class="form-control mb-2" placeholder="Enter Name..."  />
                        <input type="text" class="form-control mb-2" placeholder="Enter Email..."  />
                        <input type="text" class="form-control mb-2" placeholder="Enter Password..."  /> -->
                <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span>Register</button>
              </form>
              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div>
              <div class="reg_socialiocon">
                <ul> -->
                  <!-- <li><a href="javascript:void(0)"><img src="../../../assets/images/facebook.png"/></a></li>
                        <li><a href="javascript:void(0)"><img src="../../../assets/images/google.png"/></a></li> -->
                  <!-- <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li> -->
                  <!-- <li><a href="#"><img src="../../../assets/images/insta.png"/></a></li>
                        <li><a (click)="signInWithPinterest()"><img src="../../../assets/images/printa.png"/></a></li> -->
                <!-- </ul>
              </div> -->
            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

            <div>
              <form [formGroup]="signupForBuilder">
                <ng-container *ngIf="builderStep1">
                  <input type="text" formControlName="first_name" maxlength="100" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.first_name.errors }"
                    placeholder="Enter First Name" onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.first_name.invalid) || (!signupForBuilder.get('first_name').value && invalidForm1) || (signupForBuilder.get('first_name').invalid && signupForBuilder.get('first_name').touched)"
                    class="has-error">First Name is required
                  </div>
                  <input type="text" formControlName="last_name" maxlength="100" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.last_name.errors }"
                    placeholder="Enter Last Name" onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.last_name.invalid) || (!signupForBuilder.get('last_name').value && invalidForm1) || (signupForBuilder.get('last_name').invalid && signupForBuilder.get('last_name').touched)"
                    class="has-error"> Last Name is required
                  </div>
                  <input type="text" formControlName="email" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.email.errors }"
                    (blur)="builderMailCheck(signupForBuilder.get('email').value)" autocomplete="off"
                    placeholder="Enter Email" onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.email.invalid) || (!signupForBuilder.get('email').value && invalidForm1) || (signupForBuilder.get('email').invalid && signupForBuilder.get('email').touched)"
                    class="has-error">
                    {{ (signupForBuilder.get('email').value && signupForBuilder.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                  </div>
                  <input type="password" class="form-control mb-2" placeholder="Enter Password"
                    formControlName="password"
                    [ngClass]="{'is-invalid': (!signupForBuilder.get('password').value && invalidForm1) || (signupForBuilder.get('password').invalid && signupForBuilder.get('password').touched)}"
                    (keyup)="passwordValidationForBuilder($event)" onkeydown="return (event.keyCode!=13);">
                  <div
                    *ngIf="(invalidPassword) || (!signupForBuilder.get('password').value && invalidForm1) || (signupForBuilder.get('password').invalid && signupForBuilder.get('password').touched)"
                    class="has-error">{{ (invalidPassword) ? 'Password is invalid' : 'Password is required'}}
                  </div>
                  <input type="password" class="form-control mb-2" placeholder="Enter Confirm Password"
                    formControlName="c_password"
                    [ngClass]="{'is-invalid': (!signupForBuilder.get('c_password').value && invalidForm1) || (signupForBuilder.get('c_password').invalid && signupForBuilder.get('c_password').touched) ||(mismatchPassword)}"
                    (keyup)="compareConfirmPasswordWithNewForBuilder($event)">
                  <div
                    *ngIf="(mismatchPassword) || (!signupForBuilder.get('c_password').value && invalidForm1) || (signupForBuilder.get('c_password').invalid && signupForBuilder.get('c_password').touched)"
                    class="has-error">{{ (mismatchPassword) ? 'Mismatched Password' : 'Confirm Password is required'}}
                  </div>
                  <button class="btn reg_loginbtn w-100" (click)="toggleView('1')">Next</button>
                </ng-container>

                <ng-container *ngIf="builderStep2">
                  <input type="text" formControlName="business_name" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.business_name.errors }"
                    placeholder="Enter Business Name" onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.business_name.invalid) || (!signupForBuilder.get('business_name').value && invalidForm1) || (signupForBuilder.get('business_name').invalid && signupForBuilder.get('business_name').touched)"
                    class="has-error"> Business Name is required
                  </div>
                  <input type="text" formControlName="address_line_1" maxlength="150" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.address_line_1.errors }"
                    placeholder="Enter Address Line 1" onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.address_line_1.invalid) || (!signupForBuilder.get('address_line_1').value && invalidForm1) || (signupForBuilder.get('address_line_1').invalid && signupForBuilder.get('address_line_1').touched)"
                    class="has-error"> Address is required
                  </div>
                  <!-- <input type="text" formControlName="state" class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.state.errors }" placeholder="Enter State "/> -->
                  <!-- <select class="form-control mb-2" id="state" formControlName="state" #state (change)="stateChanged(state)" [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.state.errors }">
                          <option value="">Select any</option>
                          <option *ngFor="let state of stateList" value="{{state.code}}">{{state.text}}</option>
                        </select> -->
                  <ng-autocomplete [data]="stateList" formControlName="state" name="state" [searchKeyword]="keyword"
                    placeHolder="Enter State" (selected)="selectEvent($event, 'state_code')"
                    (inputChanged)="onChangeSearch($event, 'state_code')" (inputFocused)='onFocused($event)'
                    (inputCleared)="onClean('state_code')" historyIdentifier="stateList" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate" onkeydown="return (event.keyCode!=13);">

                  </ng-autocomplete>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.text"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.state.invalid) || (!signupForBuilder.get('state').value && invalidForm1) || (signupForBuilder.get('state').invalid && signupForBuilder.get('state').touched)"
                    class="has-error"> State is required
                  </div>
                  <!-- <input type="text" formControlName="city" class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.city.errors }" placeholder="Enter City "/> -->

                  <ng-autocomplete [data]="cityList" formControlName="city" name="city" [searchKeyword]="cityKeyword"
                    placeHolder="Enter City" (selected)="selectEvent($event, 'city_name')"
                    (inputChanged)="onChangeSearch($event, 'city_name')" (inputFocused)='onFocused($event)'
                    (inputCleared)="onClean('city_name')" historyIdentifier="stateList"
                    [itemTemplate]="itemTemplateCity" [notFoundTemplate]="notFoundTemplateCity"
                    onkeydown="return (event.keyCode!=13);">

                  </ng-autocomplete>
                  <ng-template #itemTemplateCity let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplateCity let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <!-- <select class="form-control mb-2" id="city" formControlName="city" [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.city.errors }">
                          <option value="">Select any</option>
                          <option *ngFor="let city of cityList" value="{{city.text}}">{{city.text}}</option>
                        </select> -->
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.city.invalid) || (!signupForBuilder.get('city').value && invalidForm1) || (signupForBuilder.get('city').invalid && signupForBuilder.get('city').touched)"
                    class="has-error"> City is required
                  </div>

                  <input type="text" formControlName="postal_code" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submitted1 && signupForBuilder.controls.postal_code.errors }"
                    placeholder="Enter Zip Code " onkeydown="return (event.keyCode!=13);" />
                  <div
                    *ngIf="(submitted1 && signupForBuilder.controls.postal_code.invalid) || (!signupForBuilder.get('postal_code').value && invalidForm1) || (signupForBuilder.get('postal_code').invalid && signupForBuilder.get('postal_code').touched)"
                    class="has-error"> Zip Code is required
                  </div>

                  <button class="btn reg_loginbtn w-49 ux-submit" (click)="toggleView('2')">Previous</button>
                  <button type="submit" class="btn reg_loginbtn w-49 ux-submit ux-float" [disabled]="loading"
                    (click)="onSubmitBuilderSignUpForm()"><span *ngIf="loading"
                      class="spinner-border spinner-border-sm mr-1"></span>Register</button>

                </ng-container>




              </form>
              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div>
              <div class="reg_socialiocon">
                <ul>
                  <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li> -->
                  <!-- <li><a href="#"><img src="../../../assets/images/insta.png"/></a></li>
                        <li><a (click)="signInWithPinterest()"><img src="../../../assets/images/printa.png"/></a></li> -->
                <!-- </ul>
              </div> -->
            </div>

          </div>
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <div>
              <form [formGroup]="signupForAgent">
                <ng-container *ngIf="agentStep1">
                  <input type="text" formControlName="first_name" maxlength="100" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForAgent.controls.first_name.errors }"
                    placeholder="Enter First Name" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.first_name.invalid) || (!signupForAgent.get('first_name').value && invalidFormAgent) || (signupForAgent.get('first_name').invalid && signupForAgent.get('first_name').touched)"
                    class="has-error">First Name is required
                  </div>
                  <input type="text" formControlName="last_name" maxlength="100" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForAgent.controls.last_name.errors }"
                    placeholder="Enter Last Name" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.last_name.invalid) || (!signupForAgent.get('last_name').value && invalidFormAgent) || (signupForAgent.get('last_name').invalid && signupForAgent.get('last_name').touched)"
                    class="has-error"> Last Name is required
                  </div>
                  <input type="text" formControlName="email" class="form-control mb-2"
                    (blur)="agentMailCheck(signupForAgent.get('email').value)"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForBuilder.controls.email.errors }"
                    autocomplete="off" placeholder="Enter Email" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.email.invalid) || (!signupForAgent.get('email').value && invalidFormAgent) || (signupForAgent.get('email').invalid && signupForAgent.get('email').touched)"
                    class="has-error">
                    {{ (signupForAgent.get('email').value && signupForAgent.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                  </div>
                  <input type="password" class="form-control mb-2" placeholder="Enter Password"
                    formControlName="password"
                    [ngClass]="{'is-invalid': (!signupForAgent.get('password').value && invalidFormAgent) || (signupForAgent.get('password').invalid && signupForAgent.get('password').touched)}"
                    (keyup)="passwordValidationForAgent($event)">
                  <div
                    *ngIf="(invalidPassword) || (!signupForAgent.get('password').value && invalidFormAgent) || (signupForAgent.get('password').invalid && signupForAgent.get('password').touched)"
                    class="has-error">{{ (invalidPassword) ? 'Password is invalid' : 'Password is required'}}
                  </div>
                  <input type="password" class="form-control mb-2" placeholder="Enter Confirm Password"
                    formControlName="c_password"
                    [ngClass]="{'is-invalid': (!signupForAgent.get('c_password').value && invalidFormAgent) || (signupForAgent.get('c_password').invalid && signupForAgent.get('c_password').touched) ||(mismatchPassword)}"
                    (keyup)="compareConfirmPasswordWithNewForAgent($event)">
                  <div
                    *ngIf="(mismatchPassword) || (!signupForAgent.get('c_password').value && invalidFormAgent) || (signupForAgent.get('c_password').invalid && signupForAgent.get('c_password').touched)"
                    class="has-error">{{ (mismatchPassword) ? 'Mismatched Password' : 'Confirm Password is required'}}
                  </div>
                  <input type="text" formControlName="business_name" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForAgent.controls.business_name.errors }"
                    placeholder="Enter Business Name" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.business_name.invalid) || (!signupForAgent.get('business_name').value && invalidFormAgent) || (signupForAgent.get('business_name').invalid && signupForAgent.get('business_name').touched)"
                    class="has-error"> Business Name is required
                  </div>
                  <input type="text" formControlName="license_number" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForAgent.controls.license_number.errors }"
                    placeholder="Enter License" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.license_number.invalid) || (!signupForAgent.get('license_number').value && invalidFormAgent) || (signupForAgent.get('license_number').invalid && signupForAgent.get('license_number').touched)"
                    class="has-error"> License is required
                  </div>
                  <input type="text" formControlName="broker_name" class="form-control mb-2"
                    [ngClass]="{ 'is-invalid': submittedAgent && signupForAgent.controls.broker_name.errors }"
                    placeholder="Enter Broker Name" />
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.broker_name.invalid) || (!signupForAgent.get('broker_name').value && invalidFormAgent) || (signupForAgent.get('broker_name').invalid && signupForAgent.get('broker_name').touched)"
                    class="has-error"> Broker Name is required
                  </div>
                  <!-- <button class="btn reg_loginbtn w-100" (click)="toggleAgent('2')">Next</button> -->
                  <button class="btn reg_loginbtn w-100" (click)="onSubmitAgentSignUpFormWithoutPayment()">Register</button>
                </ng-container>
                <ng-container *ngIf="agentStep2">
                  <div class="input-sec" *ngFor="let subList of subscriptionAgentList; index as i">
                    <div class="input-inner"><input formControlName="agent_subscription_id" type="radio"
                        id="{{'tier-'+i}}" [value]="subList.id">
                      <div class="new-social"><label
                          for="{{'tier-'+i}}">{{subList.name}}</label><strong>{{subList.price | currency}}</strong>
                      </div>
                    </div>
                    <div>
                      <p class="txt-left subsceri">{{subList.description}}
                      </p>
                    </div>
                  </div>
                  <div
                    *ngIf="(submittedAgent && signupForAgent.controls.agent_subscription_id.invalid) || (!signupForAgent.get('agent_subscription_id').value && invalidFormAgent) || (signupForAgent.get('agent_subscription_id').invalid && signupForAgent.get('agent_subscription_id').touched)"
                    class="has-error"> Please select one subscription to continue
                  </div>
                  <button class="btn reg_loginbtn w-20 " (click)="toggleAgent('1')">Back</button>
                  <button class="btn reg_loginbtn w-50 ml-2" (click)="onSubmitAgentSignUpForm()">Pay</button>
                </ng-container>

              </form>
              <!-- <div class="or_txtctrl">
                <span></span>
                <span class="or_txt">or</span>
                <span></span>
              </div>
              <div class="reg_socialiocon">
                <ul>
                  <li><a (click)="signInWithFB()"><img src="../../../assets/images/facebook.png" /></a></li>
                  <li><a (click)="signInWithGoogle()"><img src="../../../assets/images/google.png" /></a></li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>

      </div>


    </div>

  </div>
</div>
<!-- </div> -->

<!-- <ng-template #paymentTemplate>

  <div class="new-modal-sec">
    <div class="modal-header">
      <button type="button" class="close" (click)="paymentModalClose()">&times;</button>
    </div>
    <div class="modal-body">
    <app-payment [emailAgent]="signupForAgent.get('email').value" (saved)="savePaymentModal($event)"></app-payment>
    </div>
  </div>



</ng-template> -->